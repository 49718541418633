// Generated by Framer (12ecc5e)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-cN4na"

const variantClassNames = {jh9WtuEum: "framer-v-lmyj0w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "jh9WtuEum", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lmyj0w", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jh9WtuEum"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-smv6se"} data-framer-name={"Graphic"} fill={"black"} intrinsicHeight={198} intrinsicWidth={216} layoutDependency={layoutDependency} layoutId={"I7y6VA4K0"} style={{opacity: 0.59}} svg={"<svg width=\"216\" height=\"198\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M135.59 197.019c-1.71-6.56-3.67-12.98-5.9-19.25-3.61-10.18-5.42-15.3-5.44-15.35-4.54-13.59-9.08-27.18-13.63-40.77-.27-.82-.79-1.64-1.56-2.45.17-.37.26-.77.27-1.19L149.18.929c.43-1.24.84-1.24 1.25.01 20.76 63.52 42.58 128.67 65.45 195.44a.471.471 0 0 1-.44.63h-79.85v.01Z\" fill=\"#1D1D1D\"/><path d=\"M109.33 118.009c0 .42-.1.82-.27 1.19-.79 1.61-1.47 3.27-2.05 4.97-9.8 28.57-17.3 50.33-22.51 65.26-.75 2.15-1.37 4.34-1.85 6.59-.06.29.07.59.33.73.41.23.87.32 1.36.28l-84.21.06c-.07 0-.13-.06-.13-.13v-.03L68.44 2.369c.48-1.37.94-1.37 1.38.02 2.31 7.29 4.54 14.08 6.69 20.35 9.43 27.51 20.06 59.08 31.9 94.71.16.49.46.67.91.56h.01Z\" fill=\"#757C79\"/><path d=\"M135.59 197.019H84.34c-.49.05-.95-.04-1.36-.27a.672.672 0 0 1-.33-.73c.48-2.25 1.1-4.44 1.85-6.59 5.21-14.93 12.71-36.69 22.51-65.26.58-1.7 1.26-3.36 2.05-4.97.77.81 1.29 1.63 1.56 2.45 4.55 13.59 9.09 27.18 13.63 40.77.02.05 1.83 5.17 5.44 15.35 2.23 6.27 4.19 12.69 5.9 19.25Z\" fill=\"#4E5051\"/></svg>"} transformTemplate={transformTemplate1} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cN4na.framer-hggjhg, .framer-cN4na .framer-hggjhg { display: block; }", ".framer-cN4na.framer-lmyj0w { height: 664px; overflow: hidden; position: relative; width: 724px; }", ".framer-cN4na .framer-smv6se { aspect-ratio: 1.0909090909090908 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 663px); left: 0px; position: absolute; right: 0px; top: 50%; z-index: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 664
 * @framerIntrinsicWidth 724
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbL_x7eVEN: React.ComponentType<Props> = withCSS(Component, css, "framer-cN4na") as typeof Component;
export default FramerbL_x7eVEN;

FramerbL_x7eVEN.displayName = "Towers graphic";

FramerbL_x7eVEN.defaultProps = {height: 664, width: 724};

addFonts(FramerbL_x7eVEN, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})